<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="420px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" size="mini">
          <el-form-item label="奖品名称" required>
            <el-input
              style="width: 200px;"
              v-model="submitData.PrizeName"
            ></el-input>
          </el-form-item>
          <el-form-item label="剩余数量">
            <el-input
              v-model="submitData.SurplusCount"
              @blur="onBlurInput('SurplusCount')"
            ></el-input>
          </el-form-item>
          <el-form-item label="中奖概率">
            <div class="probability-box">
              <el-input
                v-model="submitData.WinRate"
                @blur="onBlurInput('WinRate')"
              >
                <template slot="append">%</template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="奖品图片">
            <div class="image-box">
              <el-image
                :src="submitData.PrizeImage || imgUrl"
                :preview-src-list="[submitData.PrizeImage || imgUrl]"
                :z-index="999999999999"
                fit="scale-down"
              ></el-image>
              <el-button type="primary" size="mini" @click="onShowPrizeImage"
                >选择图片</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="兑换有效期" required>
            <div class="vailgn-box">
              <el-radio-group v-model="radioIndex" @change="onChangeRadio">
                <el-radio
                  v-for="item in [
                    { name: '指定日期', value: 0 },
                    { name: '固定天数', value: 1 },
                  ]"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.name }}
                </el-radio>
              </el-radio-group>

              <div v-show="radioIndex === 0" class="date">
                <span>截止</span>
                <el-date-picker
                  v-model="submitData.ValidDate"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
                <span>前有效</span>
              </div>
              <div v-show="radioIndex === 1" class="date">
                <span>中奖后</span>
                <el-input v-model="submitData.ValidDays"></el-input>
                <span>天内有效</span>
                <div class="tips">(0表示永远有效, -1表示指定日期)</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="不作为奖品">
            <el-checkbox
              v-model="submitData.NotPrize"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
            <span class="tips">（例如：谢谢参与）</span>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="savePrize">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 图片库 -->
    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSelectPicture"
    ></DFGalleryPictrue>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";

let url = require("@/assets/img/gift.png");

export default {
  data() {
    return {
      title: "",
      imgUrl: url,
      totalProbability: 0,
      radioIndex: 0,
      submitData: {
        ActivityId: 0,
        PrizeId: 0,
        PrizeName: "",
        SurplusCount: 0,
        WinRate: 0,
        PrizeImage: "",
        ValidDate: "",
        ValidDays: -1,
        NotPrize: 0,
      },
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 保存奖品信息
    async savePrize() {
      let { submitData } = this;
      if (!submitData.PrizeName) {
        return this.$message.error("请输入奖品名称");
      } else if (Number(submitData.ValidDays) === -1 && !submitData.ValidDate) {
        return this.$message.error("请输入指定有效日期");
      } else if (submitData.ValidDays === "") {
        return this.$message.error("请输入固定有效日期");
      } else if (this.totalProbability + Number(submitData.WinRate) > 100) {
        return this.$message.error("中奖概率不能超过100%");
      }

      try {
        let { data, errcode } = await Raffle.savePrize({
          ...submitData,
          SurplusCount: Number(submitData.SurplusCount),
          WinRate: Number(submitData.WinRate),
          ValidDays: Number(submitData.ValidDays),
        });
        if (errcode === 0) {
          submitData.PrizeId = data
          this.$message.success("保存成功");
          this.$emit("success", JSON.parse(JSON.stringify(submitData)));
          this.onCloseDialog();
        } else {
          this.$message.error(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 打开图库对话框
    onShowPrizeImage() {
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 选择图片
    onSelectPicture(event) {
      this.submitData.PrizeImage = event[0].MediaUrl;
    },

    // 单选改变事件
    onChangeRadio() {
      if (this.radioIndex === 0) {
        this.submitData.ValidDays = -1;
        this.submitData.ValidDate = "";
      } else {
        this.submitData.ValidDays = "";
        this.submitData.ValidDate = "";
      }
    },

    onBlurInput(keyName) {
      if (Number(this.submitData[keyName]) < 0) {
        this.submitData[keyName] = 0;
        return this.$message.error("请输入不小于0的数字");
      }
    },

    resetData() {
      this.submitData = {
        PrizeId: 0,
        PrizeName: "",
        SurplusCount: 0,
        WinRate: 0,
        PrizeImage: "",
        ValidDate: "",
        ValidDays: -1,
        NotPrize: 0,
      };
    },

    // 打开对话框
    onShowDialog(event = {}, totalProbability) {
      let param = JSON.parse(JSON.stringify(event));
      this.submitData.ActivityId = param.ActivityId;
      this.title = param.PrizeId ? "修改奖品" : "添加奖品";
      this.submitData = param.PrizeId
        ? {
            ...param,
            ValidDate: param.ValidDate
              ? this.util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(param.ValidDate * 1000)
                )
              : "",
          }
        : this.submitData;
      this.totalProbability = JSON.parse(JSON.stringify(totalProbability));
      // console.log(this.submitData);
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-input {
    width: 140px;
  }

  .probability-box {
    .flex-col;
    align-items: unset;
  }
  .image-box {
    .flex-row;
    .el-image {
      margin-right: 10px;
    }
    .el-image {
      width: 80px;
      height: 80px;
    }
  }

  .vailgn-box {
    .flex-col;
    align-items: unset;

    .el-radio-group {
      margin-block-start: 6px;
    }
    .date {
      .flex-row;
      flex-wrap: wrap;
      margin-block-start: 10px;

      .el-input {
        width: 140px;
        margin-inline: 10px;
      }
      .tips {
        width: 100%;
      }
    }
  }

  .tips {
    color: red;
    font-size: 12px;
  }
}
</style>
