<template>
  <div>
    <el-dialog
      title="奖品设置"
      :visible.sync="showDialog"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="df-row">
          <div class="df-col">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="onShowAdd"
              >添加奖品</el-button
            >
          </div>
          <div class="df-col">
            <span class="tips">当前概率合计：</span>
            <span class="tips red">{{ totalProbability }}%</span>
            <el-popover placement="top" trigger="hover">
              <div class="content">
                所有奖品的中奖率累计加起来不能大于100%（包括谢谢参与）
              </div>
              <i class="el-icon-warning-outline" slot="reference"></i>
            </el-popover>
          </div>
        </div>

        <el-table
          :data="tableList"
          max-height="400px"
          size="mini"
          border
          stripe
        >
          <el-table-column prop="" label="奖品图片" width="80px">
            <template slot-scope="scope">
              <el-image :src="scope.row.PrizeImage || imgUrl"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="PrizeName" label="奖品名称"></el-table-column>
          <el-table-column
            prop="SurplusCount"
            label="剩余数量"
          ></el-table-column>
          <el-table-column prop="WinRate" label="中奖概率(%)"></el-table-column>
          <el-table-column prop="" label="操作" width="160px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="onShowAdd(scope.row)"
                >修改</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deletePrize(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <!-- <el-button type="primary" @click="onCloseDialog">确 定</el-button> -->
      </span>
    </el-dialog>

    <AddPrizeDialog
      ref="AddPrizeDialog"
      @success="getPrizeList"
    ></AddPrizeDialog>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";
import AddPrizeDialog from "./add-prize-dialog.vue";

let url = require("@/assets/img/gift.png");
export default {
  components: { AddPrizeDialog },

  data() {
    return {
      activityId: 0,
      imgUrl: url,
      totalProbability: 0,
      tableList: [],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 获取奖品列表
    async getPrizeList() {
      try {
        let { data } = await Raffle.getPrizeList({
          activity_id: this.activityId,
        });
        this.tableList = data;
        this.totalProbability = data.reduce(
          (prev, cur) => prev + cur.WinRate,
          0
        );
      } catch (err) {
        console.log();
      }
    },

    // 删除奖品
    deletePrize(event) {
      this.$confirm("确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Raffle.deletePrize({
            prize_id: event.PrizeId,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getPrizeList();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 打开奖品详情对话框
    onShowAdd(event = {}) {
      let { totalProbability } = this;
      this.$refs.AddPrizeDialog.onShowDialog(
        {
          ...event,
          ActivityId: this.activityId,
        },
        totalProbability - (event.WinRate || 0)
      );
    },

    // 打开对话框
    onShowDialog(activityId = 0) {
      // console.log(activityId);
      this.activityId = activityId;
      this.getPrizeList();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .df-row {
    .df-col {
      .flex-row;

      .tips {
        font-size: 16px;
      }
      .red {
        color: red;
      }
    }
  }

  .el-table {
    .el-image {
      width: 40px;
      height: 40px;
    }
  }
}
.market-i(5px);
.market-popover();
</style>
