<template>
  <div>
    <el-dialog
      title="抽奖活动信息"
      :visible.sync="showDialog"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" size="mini">
          <el-form-item label="活动名称" required>
            <el-input
              class="activities-name"
              v-model="submitData.ActivityName"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间" required>
            <el-date-picker
              v-model="multipleDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="onChangeDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="可用门店" required>
            <el-select v-model="submitData.ValidStoreList" multiple>
              <el-option
                v-for="item in storeList"
                :key="item.StoresGuid"
                :label="item.StoresName"
                :value="item.StoresGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单个用户总抽奖次数" required>
            <el-input
              v-model="submitData.PersonSumLimit"
              @blur="onBlurInput('PersonSumLimit')"
            ></el-input>
            <span class="tips">活动结束前，每位用户累计可抽奖次数</span>
          </el-form-item>
          <el-form-item label="每日可抽奖次数" required>
            <el-input
              v-model="submitData.PersonDayLimit"
              @blur="onBlurInput('PersonDayLimit')"
            ></el-input>
            <span class="tips">活动结束前，每位用户每天最多可抽奖次数</span>
          </el-form-item>
          <el-form-item label="活动介绍">
            <el-button type="primary" size="mini" @click="onShowIntroduce"
              >编辑</el-button
            >
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveActivity">确 定</el-button>
      </span>
    </el-dialog>

    <DfQuilEditorDrawer
      ref="DfQuilEditorDrawer"
      @onBlurText="onBlurEdit"
    ></DfQuilEditorDrawer>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";
import Common from "@/api/common.js";

export default {
  data() {
    return {
      submitData: {
        ActivityId: 0,
        ActivityName: "",
        BeginTime: "",
        EndTime: "",
        PersonSumLimit: 1,
        PersonDayLimit: 1,
        ActivityIntro: "",
        ValidStoreList: [],
      },
      multipleDate: [],
      storeList: [],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化活动信息
    async initActivity() {
      try {
        let { data } = await Raffle.initActivity({
          activity_id: this.submitData.ActivityId,
        });
        let BeginTime = this.util.dateFormat(
            "YYYY-mm-dd HH:MM:SS",
            new Date(data.editor.BeginTime * 1000)
          ),
          EndTime = this.util.dateFormat(
            "YYYY-mm-dd HH:MM:SS",
            new Date(data.editor.EndTime * 1000)
          );
        this.submitData = {
          ...data.editor,
          BeginTime,
          EndTime,
        };
        this.multipleDate = [BeginTime, EndTime];
      } catch (err) {
        console.log(err);
      }
    },

    // 获取门店列表
    async getStoreList() {
      try {
        let { data } = await Common.getStoreList();
        this.storeList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存活动信息
    async saveActivity() {
      let { submitData } = this;
      if (!submitData.ActivityName) {
        return this.$message.error("请输入活动名称");
      } else if (!submitData.BeginTime || !submitData.EndTime) {
        return this.$message.error("请选择活动时间");
      } else if (!submitData.PersonSumLimit) {
        return this.$message.error("请输入单个用户总抽奖次数");
      } else if (!submitData.PersonDayLimit) {
        return this.$message.error("请输入每日可抽奖次数");
      } else if (!submitData.ValidStoreList) {
        return this.$message.error("请选择可用门店");
      }

      try {
        let { errcode } = await Raffle.saveActivity({
          ...submitData,
          PersonSumLimit: Number(submitData.PersonSumLimit),
          PersonDayLimit: Number(submitData.PersonDayLimit),
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.submitData.BeginTime = event[0];
      this.submitData.EndTime = event[1];
    },

    onBlurInput(keyName) {
      this.submitData[keyName] =
        this.submitData[keyName] == "0" ? 1 : this.submitData[keyName];
    },

    // 活动介绍赋值
    onBlurEdit(event) {
      this.submitData.ActivityIntro = event;
    },

    // 打开活动介绍对话框
    onShowIntroduce() {
      this.$refs.DfQuilEditorDrawer.onShowDrawer(this.submitData.ActivityIntro);
    },

    resetData() {
      this.submitData = {
        ActivityId: 0,
        ActivityName: "",
        BeginTime: "",
        EndTime: "",
        PersonSumLimit: 1,
        PersonDayLimit: 1,
        ActivityIntro: "",
        ValidStoreList: [],
      };
    },

    // 打开对话框
    onShowDialog(activityId = 0) {
      this.submitData.ActivityId = activityId;
      this.getStoreList();
      if (activityId) {
        this.initActivity();
      } else {
        this.multipleDate = [
          this.util.dateFormat("YYYY-mm-dd HH:MM:SS", new Date()),
          this.util.dateFormat(
            "YYYY-mm-dd HH:MM:SS",
            this.util.dateAdd(new Date(), "d", 1)
          ),
        ];
      }

      this.onChangeDate(this.multipleDate);
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-date-editor {
    width: 340px;
  }

  .el-input {
    width: 80px;
  }

  .el-select {
    width: 340px;
  }

  .tips {
    margin-inline-start: 10px;
    color: red;
    font-size: 12px;
  }

  .activities-name {
    width: 200px;
  }
}
</style>
