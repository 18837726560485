<template>
  <div class="activities">
    <div class="df-row">
      <div class="df-col">
        <el-select
          v-model="submitData.status"
          size="mini"
          placeholder="活动状态"
        >
          <el-option
            v-for="item in statusList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-input
          v-model="submitData.word"
          placeholder="请输入活动名称"
          size="mini"
          clearable
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="onShowAdd"
          >创建活动</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableList"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="ActivityName" label="活动名称"></el-table-column>
      <el-table-column prop="" label="开始时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.BeginTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="结束时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.EndTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="浏览量"></el-table-column> -->
      <el-table-column prop="" label="状态">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.Status == 0
                ? 'info'
                : scope.row.Status == 1
                ? 'success'
                : 'danger'
            "
            size="small"
            effect="dark"
            >{{
              scope.row.Status == 0
                ? "未开始"
                : scope.row.Status == 1
                ? "进行中"
                : "已结束"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="280">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onShowAdd(scope.row)"
            >编辑</el-button
          >
          <el-button type="primary" size="mini" @click="onShowPrize(scope.row)"
            >设置奖品</el-button
          >
          <!-- <el-button type="primary" plain size="mini" @click=""
            >分享活动</el-button
          > -->
          <el-button
            type="danger"
            size="mini"
            @click="deleteActivity(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <AddActivitiesDialog
      ref="AddActivitiesDialog"
      @success="onSearch"
    ></AddActivitiesDialog>

    <PrizeSettingDialog
      ref="PrizeSettingDialog"
      @success=""
    ></PrizeSettingDialog>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";
import AddActivitiesDialog from "./components/add-activities-dialog.vue";
import PrizeSettingDialog from "./components/prize-setting-dialog.vue";

export default {
  components: { AddActivitiesDialog, PrizeSettingDialog },

  data() {
    return {
      tableMaxHeight: 0,
      totalRecord: 0,
      submitData: {
        status: -1,
        word: "",
        page_size: 10,
        page_index: 1,
      },
      statusList: [
        {
          Name: "全部",
          Value: -1,
        },
        {
          Name: "未开始",
          Value: 0,
        },
        {
          Name: "进行中",
          Value: 1,
        },
        {
          Name: "已结束",
          Value: 2,
        },
      ],
      tableList: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    this.getActivityMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 获取活动列表
    async getActivityMaintList() {
      this.loading = true;
      try {
        let { data } = await Raffle.getActivityMaintList(this.submitData);
        this.tableList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除活动
    deleteActivity(event) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Raffle.deleteActivity({
            activity_id: event.ActivityId,
          });
          this.$message.success("删除成功");
          this.getActivityMaintList();
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getActivityMaintList();
    },

    // 打开创建活动对话框
    onShowAdd(event = {}) {
      this.$refs.AddActivitiesDialog.onShowDialog(event.ActivityId || 0);
    },

    // 打开奖品设置对话框
    onShowPrize(event = {}) {
      // console.log(event);
      this.$refs.PrizeSettingDialog.onShowDialog(event.ActivityId || 0);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.getActivityMaintList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.getActivityMaintList();
    },
  },
};
</script>

<style lang="less" scoped>
.activities {
  .df-row {
    margin-block-start: 20px;
  }

  .el-pagination {
    margin-block-start: 20px;
    text-align: right;
  }
}

::v-deep .el-popover {
  padding: 10px !important;
}
</style>
